exports = module.exports = require("../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._12TXvPxfD5u4FxHwdcByP0{font-size:14px}._12TXvPxfD5u4FxHwdcByP0 div{padding:10px 15px}._12TXvPxfD5u4FxHwdcByP0 h4{font-size:16px;font-weight:500;line-height:1.1;margin-bottom:5px}._12TXvPxfD5u4FxHwdcByP0 p{line-height:1.3;margin-bottom:15px}", ""]);

// exports
exports.locals = {
	"sidecarModal": "_12TXvPxfD5u4FxHwdcByP0"
};